import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";

import paginationActions from "actions/pagination";
import { selectListLoading } from "selectors/pagination";
import generateTransition from "utils/generateTransition";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  listSearch: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 300,
    border: `1px solid ${colours.newMidDarkGrey}`,
    position: "relative",
    color: colours.bodyText,
    height: "2.375rem",
    transition: generateTransition({ target: "box-shadow", speed: "250ms" }),
    flex: 1,
    width: "100%",
    minHeight: "2.375rem",

    [ScreenSizes.mdAndAbove]: {
      minWidth: "8rem",
    },
    [ScreenSizes.xlAndAbove]: {
      minWidth: "10.625rem",
    },
    ...getHoverQuery({
      boxShadow: "0 4px 14px 0 rgba(0,0,0,0.05)",
    }),
  },
  listSearchInput: {
    ...gStyles.fontLight,
    fontSize: ".75rem",
    width: "100%",
    height: "100%",
    padding: "0 12px 0 35px",
    outline: "none",
    WebkitAppearance: "none",
    border: "none",
  },
  listSearchInputLabel: {
    maxWidth: 0,
    maxHeight: 0,
    overflow: "hidden",
  },
  listSearchPlaceholder: {
    ...gStyles.fontSemiBold,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: ".75rem",
    transformText: "uppercase",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 25,
    pointerEvents: "none",
    zIndex: 1,
    transition: generateTransition({ target: "opacity", speed: "300ms" }),
    opacity: 0,

    [ScreenSizes.lgAndAbove]: {
      fontSize: ".625rem",
      left: 35,
    },
  },
  listSearchPlaceholderShow: {
    opacity: 1,
  },
  listSearchIcon: {
    position: "absolute",
    left: 10,
    top: 0,
    bottom: 0,
    width: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: ".75rem",
    pointerEvents: "none",

    [ScreenSizes.lgAndAbove]: {
      width: 25,
    },
  },
  clearButton: {
    cursor: "pointer",
    pointerEvents: "auto",
  },
};

const ListSearch = (props) => {
  const {
    placeholder,
    value,
    setValue,
    listKey,
    onChange,
    setIsSearching,
    dontLoadFilters,
  } = props;

  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const [hasFocus, setHasFocus] = useState(false);

  const loading = useReduxState(
    (state) => selectListLoading(state, { key: listKey }),
    [listKey]
  );

  const { setSearchTerm } = useActionCreators(paginationActions);

  const handleSearch = useMemo(
    () =>
      debounce(
        (trimmedValue) => {
          if (listKey) {
            setSearchTerm(listKey, trimmedValue, null, dontLoadFilters);
          }
        },
        mobile ? 800 : 250
      ),
    [setSearchTerm, listKey, mobile, dontLoadFilters]
  );

  const handleChange = useCallback(
    (e) => {
      const { value: targetValue } = e.target;
      setValue(targetValue);

      const trimmedValue = targetValue.trim();

      if (onChange && !mobile) {
        onChange(trimmedValue);
      } else {
        handleSearch(targetValue);
      }

      setIsSearching && setIsSearching(targetValue.length > 0);
    },
    [handleSearch, onChange, setIsSearching, mobile]
  );

  const handleFocus = useCallback(() => setHasFocus(true), []);
  const handleBlur = useCallback(() => setHasFocus(false), []);

  const handleClear = useCallback(() => {
    setValue("");
    setIsSearching && setIsSearching(false);
    if (listKey) {
      setSearchTerm(listKey, "", null, dontLoadFilters);
    }
  }, [setSearchTerm, listKey, setIsSearching, dontLoadFilters]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      handleSearch(value);
    },
    [handleSearch, value]
  );

  const { icon, iconOnClick, iconClassName, spin } = useMemo(() => {
    if (loading) {
      return {
        icon: faCircleNotch,
        spin: true,
      };
    }
    if (value?.trim() !== "" && value?.trim()?.length > 0) {
      return {
        icon: faTimes,
        iconOnClick: handleClear,
        iconClassName: styles.clearButton,
      };
    }
    return { icon: faSearch };
  }, [loading, value, handleClear, styles.clearButton]);

  const hasValue = value?.trim() !== "";

  return (
    <form className={css(styles.listSearch)} onSubmit={handleSubmit}>
      <input
        id={`${listKey}-list-search-input`}
        className={css(styles.listSearchInput)}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
      />
      <label
        htmlFor={`${listKey}-list-search-input`}
        className={css(styles.listSearchInputLabel)}
      >
        List Search Input
      </label>
      <div
        className={css(
          styles.listSearchPlaceholder,
          !hasFocus &&
            !hasValue &&
            placeholder &&
            styles.listSearchPlaceholderShow
        )}
      >
        {mobile ? "Search..." : placeholder}
      </div>
      <div className={css(styles.listSearchIcon)}>
        <FontAwesomeIcon
          className={iconClassName && css(iconClassName)}
          icon={icon}
          spin={spin}
          onClick={iconOnClick}
        />
      </div>
    </form>
  );
};

ListSearch.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  listKey: PropTypes.string,
  dontLoadFilters: PropTypes.bool,
};

ListSearch.defaultProps = {
  value: "",
  placeholder: null,
  onChange: null,
  listKey: null,
  dontLoadFilters: null,
};

export default ListSearch;
